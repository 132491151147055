import CarImage from '../images/car.png';
import CashImage from '../images/cash.png';

export function InfoPoster() {
  return (
    <div>
      <div className='flex flex-col-2 justify-evenly my-4'>
        <img
          src={CarImage}
          alt='Bil'
        />
        <img
          src={CashImage}
          alt='Penger'
        />
      </div>
      <div className='mt-8 text-2xl font-bold'>
        Dette påvirker bilens markedspris
      </div>
      <div className='mb-8'>
        <p className='my-3'>
          Markedsprisen på bilen din påvirkes av mange faktorer, blant annet
          popularitet på modellen, utstyrsnivå, farge, tilstand på bilen og
          dekk. Vårt verktøy vil ta dette i betraktning og estimerer en pris som
          vi tror er riktig salgspris på det åpne markedet.
        </p>
        <p className='font-bold my-3'>
          Prisen du får oppgitt er ikke et pristilbud fra oss, men en indikasjon
          på markedspris basert på opplysninger om tilsvarende solgte biler.
        </p>
        <p className='my-3'>
          Om det ikke foreligger nok informasjon om din bilmodell, tar vi gjerne
          en manuell vurdering av ditt kjøretøy helt kostnadsfritt. Send inn
          skjemaet så tar vi kontakt med deg og avtaler tid for vurdering.
        </p>
      </div>
    </div>
  );
}
