export default function Button({
  label,
  onClick,
  disabled = false,
  largeFont = false,
}) {
  let font = largeFont ? 'text-lg' : 'text-md';

  let className = !disabled
    ? `w-full inline-flex flex-col items-center px-4 py-2 border border-transparent ${font} uppercase font-medium rounded-md shadow-sm drop-shadow-lg text-white bg-sentinel hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sentinel`
    : `w-full inline-flex flex-col items-center px-4 py-2 border border-transparent ${font} uppercase font-medium rounded-md shadow-sm drop-shadow-lg text-white bg-gray-400`;

  return (
    <button
      type='button'
      disabled={disabled}
      onClick={onClick}
      className={className}
    >
      {label}
    </button>
  );
}
