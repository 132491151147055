import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';

const AppWrapper =
  process.env.NODE_ENV === 'development' ? React.StrictMode : React.Fragment;

const root = ReactDOM.createRoot(document.getElementById('app'));

root.render(
  <AppWrapper>
    <App />
  </AppWrapper>
);
