import agreeImage from '../images/agree.png';
import handshakeImage from '../images/handshake.png';
import addImage from '../images/addimage.png';

import AreaInput from './AreaInput';
import Button from './Button';
import TextInput from './TextInput';
import {
  isTextValidEmailAddress,
  isTextValidTelephone,
  isTextValidZipCode,
} from '../utils/util';
import { useState } from 'react';

export default function Page2({
  sendContactData,
  form,
  onChange,
  onFileChange,
  images,
  isSending,
}) {
  const [validField, setValidField] = useState({
    name: false,
    zipCode: false,
    email: false,
    telephone: false,
  });
  const [isValid, setIsValid] = useState(false);

  function handleChange(event, isValid) {
    const valid = { ...validField, [event.target.name]: isValid };
    setValidField(valid);
    setIsValid(Object.values(valid).every((el) => el === true));
    onChange(event);
  }

  return (
    <div className='grid grid-cols-1 gap-8 md:grid-cols-2'>
      <div className=''>
        <div className='mt-5 text-2xl font-bold'>Kontaktinformasjon</div>

        <div className='grid grid-cols-1 md:grid-cols-3 gap-x-5 mb-4'>
          <div className='md:col-span-2'>
            <TextInput
              label='Navn'
              name='name'
              value={form.name}
              requireValue={true}
              onChange={handleChange}
            />
          </div>
          <div className=''>
            <TextInput
              label='Postnummer'
              name='zipCode'
              value={form.zipCode}
              requireValue={true}
              validator={isTextValidZipCode}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className='mb-4'>
          <TextInput
            label='Epost'
            name='email'
            value={form.email}
            requireValue={true}
            validator={isTextValidEmailAddress}
            onChange={handleChange}
          />
        </div>
        <div className='mb-4'>
          <TextInput
            label='Telefon'
            name='telephone'
            value={form.telephone}
            requireValue={true}
            validator={isTextValidTelephone}
            onChange={handleChange}
          />
        </div>

        <AreaInput
          label='Kommentar'
          name='comment'
          value={form.comment}
          onChange={handleChange}
        />

        <div className='mt-8 my-4'>
          <div className='font-medium mb-2'>Last opp bilder av bilen</div>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4 grid-flow-row'>
            <label
              className='cursor-pointer focus:outline-none hover:bg-gray-100'
              htmlFor='image_upload'
            >
              <img
                src={addImage}
                alt='Legg til bilde'
              />
            </label>
            {images.length > 0
              ? images.map((image, idx) => {
                  return (
                    <img
                      className='max-w-[143]'
                      key={idx}
                      src={image}
                      alt=''
                    />
                  );
                })
              : null}
          </div>
          <div className='text-xs text-gray-500 mt-5'>
            (last gjerne opp bilder av bilen fra alle fire sider, samt bilde av
            førersete og dashbord)
          </div>
        </div>
        <input
          type='file'
          id='image_upload'
          className='opacity-0'
          onChange={onFileChange}
          accept='image/*'
          multiple
        />

        <div>
          <Button
            disabled={!isValid || isSending}
            label='Send'
            onClick={sendContactData}
            largeFont={true}
          />
        </div>
        <div className='text-xs text-gray-500 mt-5'>
          Ved å sende denne henvendelsen godtar du våre retningslinjer for
          personvern. Vi er nødt til å samle inn og behandle dine opplysninger
          for å følge opp din henvendelse.
        </div>
      </div>
      <div className='pt-4 mx-20'>
        <div className='flex'>
          <img
            src={agreeImage}
            alt='Logo'
            className='mx-auto'
          />
          <img
            src={handshakeImage}
            alt='Logo'
            className='mx-auto'
          />
        </div>
        <div className='mt-8'>
          <h3 className='mt-10 text-2xl font-bold'>Hva skjer videre?</h3>
          <div className='mb-8 mt-2'>
            <p className='mb-4'>
              Takk for at du tok kontakt med oss! For at vi skal kunne gi deg et
              tilbud på kjøp av din bil, må vi ta en inspeksjon av bilen din.
              Dette er for å avdekke mulige avvik før vi tar inn bilen og for å
              unngå forsinkelser og misforståelser ved innlevering.
            </p>
            <p className='mb-4'>
              Vi minner om at markedsprisen presentert på denne siden er en
              indikasjon på pris på det åpne markedet, basert på data vi har om
              andre tilsvarende bilder.
            </p>
            <p>
              Prisen vi som forhandler kan betale for bilen vil være lavere enn
              dette. En forhandler har kostnader og overtar risiko i forbindelse
              med salg av bilen og må kalkulere inn dette samt en fortjeneste i
              prisen vi kan betale.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
